import Service from "../../Service";

const resource = "HmnMassiveAssistanceGang/";

export default {

    save(list, requestID) {
        // return Service.post(resource + "save", list, {
        //     responseType: "json",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // });
        return Service.post(resource + "save", list, {
            params: { requestID: requestID },
        });
    },
    

    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },

};