<template>
    <div>
    <v-row>
      <v-col>
        <s-crud
          :single-row="false"
          add
          addNoFunction
          @add="addWorker()"
          searchInput
          @rowSelected="selectedWorker($event)"
          ref="ScrudAssitanceGang"
          title="Contratos"
          :config="config"
          :filter="filter"
          @doubleClick="assignWorker($event)"
        >
          <template v-slot:filter>
            <v-container>
              <v-row justify="center">
                <v-col lg="6"> 
                    <s-select
                        label="Cuadrilla"
                        :items="quadrille"
                        autocomplete
                        item-text="GngName"
                        item-value="GngID"
                        v-model="filter.GngID"
                    ></s-select>
                </v-col>
            </v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
      <v-col>
        <s-toolbar
          label="Asistencia Masiva"
          remove
          :search="false"
          @removed="removeEvent($event)"
        >
        <template v-slot:options>
            <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" small @click="massiveAssistance()" elevation="0">
                        <v-icon style="font-size:18px;">mdi-calendar-clock</v-icon>
                    </v-btn>
                </template>
                <span>Asistencia masiva</span>
            </v-tooltip>
        </template>
        </s-toolbar>
        <v-card>
          <v-data-table
            dense
            @click:row="rowClick"
            selectable-key="isSelectable"
            v-model="selected"
            :headers="configPanel.headers"
            item-key="PrsDocumentNumber"
            :items="itemsCard"
          ></v-data-table>
        </v-card>
        <v-progress-circular 
          v-if="loading"
          indeterminate
          :size="50"
          color="primary"
        ></v-progress-circular>
      </v-col>

    </v-row>
    
    <!-- CAMBIAR TURNO MASIVO -->
    <v-dialog v-model="showMassiveAssistance" v-if="showMassiveAssistance" persistent max-width="500">
      <v-card>
        <v-card-title>Asignar asistencia masiva</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <v-row style="padding-top:12px;">
                <v-col cols="6" class="s-col-form">
                    <s-select-definition :def="1527" label="Estado Marcación" v-model="item.MrkTypeStatusMarking"></s-select-definition>
                </v-col>
                <v-col cols="6" class="s-col-form">
                    <s-date label="Marcacion *" type="datetime" v-model="item.MrkMarking"></s-date>
                </v-col>
                <!-- <v-col cols="6" class="s-col-form">
                    <s-select-definition :def="1527" label="Estado Marcación" :id="'DedAbbreviation'" v-model="item.MrkStatusMarking"></s-select-definition>
                </v-col> -->
                <!-- <v-col cols="6" class="s-col-form">
                    <s-text  label="Minutos Refrigerio" v-model="item.MrkOfficialRefreshment"></s-text>
                </v-col>
                <v-col cols="6" class="s-col-form">
                    <s-date label="Entrada Oficial" type="datetime" v-model="item.MrkOfficialEntry"></s-date>
                </v-col>
                <v-col cols="6" class="s-col-form">
                    <s-date label="Salida Oficial" type="datetime" v-model="item.MrkOfficialExit"></s-date>
                </v-col> -->
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel()">Cancelar</v-btn>
          <v-btn color="accent" @click="save()">
            <span v-if="isLoading">
              <i class="fa fa-spinner fa-spin"></i>
            </span>
            <span v-else>
              Guardar
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </template>
  
  <script>
  import _sChangeShiftMassService from "@/services/HumanResource/ChangeShiftMass";
  import _sMassiveAssistanceGangService from "@/services/HumanResource/Compensation/HmnMassiveAssistanceGangService";
  import _sHelper from "@/services/HelperService";
  import _sGenParam from "@/services/General/ParameterService";
  import _sQuadriService from "@/services/HumanResource/hmnGang.js";
  
  export default {
    components: { },
    data() {
      
      return {
        selected: [],
        itemsCard: [],
        selectionBefore: [],
        filter: {
            GngID: 1 
        },
        isLoading: false,
        showDiaglog: false,
        quadrille: [],
        showMassiveAssistance: false,
        item : [],
        config: {
          service: _sMassiveAssistanceGangService,
          model: {
            PrsDocumentNumber: "ID",
            AgtBeginDate: "date",
          },
          headers: [
            { text: "Documento", value: "PrsDocumentNumber", sortable: false },
            { text: "Empleado", value: "NtpFullName", sortable: true },
            { text: "Fecha Inicio Contrato", value: "AgtBeginDate", sortable: true },
            { text: "Cuadrilla", value: "GngName", sortable: true },
            { text: "Area", value: "AreName", sortable: true },
          ],
        },
        configPanel: {
          headers: [
            { text: "ID", value: "AgtID", sortable: false },
            { text: "Documento", value: "PrsDocumentNumber", sortable: false },
            { text: "Empleado", value: "NtpFullName", sortable: true },
          ],
        },
        loading: false,
      };
    },
    methods: {
        initialize() {
            this.getQuadrille();
        },
        getQuadrille() {
            let obj = {
                SecStatus: 1,
            };
            _sQuadriService.list(obj, this.$fun.getUserID()).then((resp) => {
                if (resp.status == 200) {
                this.quadrille = resp.data;
                }
            });
        },
        addWorker() {
            console.log(this.selectionBefore);
            this.selectionBefore.forEach((element) => {
            console.log(element);
            if (this.itemsCard.find((x) => x == element) == null) this.itemsCard.push(element);
            });
        },
        selectedWorker(items) {
            this.selectionBefore = items;
        },
        removeEvent(value) {
            if (this.selected.length == 1) {
            this.itemsCard = this.itemsCard.filter((x) => x != this.selected[0]);
            }
        },
        clear() {
            this.itemsCard = [];
        },
        rowClick: function(item, row) {
    
            if (true) {
            this.selected = [item];
            this.row = Object.assign({}, item);
            console.log("Row", item);
            } else {
            if (
                this.selected.find((x) => {
                return x == item;
                }) != null
            )
                this.selected = this.selected.filter((x) => {
                return x != item;
                });
            else {
                this.selected.push(item);
                this.row = Object.assign({}, item);
            }
            }
        },
        assignWorker(worker) {
            if (this.itemsCard.find((x) => x == worker) == null) this.itemsCard.push(worker);
        },
        massiveAssistance() {
            if (this.itemsCard.length === 0) {
            this.$fun.alert("Por favor seleccione al menos un empleado", "warning");
            } else {
            this.showMassiveAssistance = true;
            }
        },
        cancel() {
            this.showMassiveAssistance = false;
        },
        save() {

            this.isLoading = true;
            const datosAEnviar = this.itemsCard.map(worker => {
                return {
                    ...worker,
                    MrkMarking: this.item.MrkMarking,
                    MrkTypeStatusMarking: this.item.MrkTypeStatusMarking,
                    UsrCreateID: this.$fun.getUserID(),
                    UsrID: this.$fun.getUserID()
                };
            });

            _sMassiveAssistanceGangService.save(datosAEnviar, this.$fun.getUserID())
                .then((response) => {
                  if(response.status == 200) {
                    this.itemsCard = [];
                    this.showMassiveAssistance = false;
                    this.isLoading = false;
                    this.$refs.ScrudAssitanceGang.refresh();
                    this.$fun.alert("Asistencia asignada correctamente", "success");
                  }
                })
                .catch((error) => {
                    this.$fun.alert(error.response?.data?.message || "Error al procesar la solicitud", "error");
                    console.error('Error:', error);
                    this.isLoading = false;
                });
        },
    },
    created(){
        this.initialize();
    }
  };
  </script>
  